import React from "react"
import {
  Button,
  Card,
  Col,
  Container,
  Row
} from "react-bootstrap"
import Layout from "../components/Layout"
import classNames from "classnames"
import { ServiceListHandler } from "../utils/bicycleServices.util"
import { navigate } from "gatsby"

/*
 todo: Get booking number
*/

const BicycleServices = ({ location }) => {

  let firstName, lastName, email, phone, appointmentDate = "";
  let store, service = {};
  let serviceDetails = [];

  if (location.state && location.state.formModel) {
    ({ firstName, lastName, phone, email, appointmentDate, store, service, serviceDetails } = location.state.formModel);
  } else {
    typeof window !== 'undefined' && navigate('/');
  }

  return (
    <Layout
      staticSeo={{
        seoTitle: "Booking confirmed",
        seoDescription: "Booking confirmed",
      }}
      location={{ pathname: "bicycleServicesSuccess" }}>
      <Container fluid="xl">
        <Row className="justify-content-center">
          <Col md={10}>

            <h1 className="text-uppercase text-center">Your booking is confirmed!</h1>
            <p className="text-grey-600 text-center">
              Thank you for booking, we are looking forward to seeing you in our workshop.
              Should you like to contact us, please do so at <a href={`mailto:${process.env.ORG_EMAIL}`}>{process.env.ORG_EMAIL}</a>
            </p>
            {
              location.state && location.state.formModel &&
              <>
                <Card className="my-5" body>
                  <h2 className="text-uppercase mb-4">Booking Information</h2>
                  <Row>
                    <Col sm={6}>
                      <h4 className={h4HeadingClass}>Customer details</h4>
                      <p>
                        {firstName} {lastName}
                        <br />
                        {email}
                        <br />
                        {phone}
                      </p>
                    </Col>
                    <Col sm={6}>
                      {/* <h4 className={h4HeadingClass}>Booking Number</h4>
                      <p>15487</p> */}
                    </Col>
                  </Row>
                </Card>
                <h2 className="text-uppercase mb-4">Appointment</h2>
                <Row className="mb-4">
                  <Col sm={6}>
                    <h4 className={h4HeadingClass}>Date of Appointment</h4>
                    <p>{appointmentDate}</p>
                  </Col>
                  <Col sm={6}>
                    <h4 className={h4HeadingClass}>Store of Appointment</h4>
                    <p>{store.name}</p>
                  </Col>
                </Row>
                <hr />

                <h2 className="text-uppercase mb-4 mt-5">Services Booked</h2>
                <h4 className={h4HeadingClass}>{service.name} Services</h4>

                <ServiceListHandler service={service} serviceDetails={serviceDetails} />

                <div className={serviceRowTotalClass}>
                  <small className="font-weight-bold">TOTAL</small>
                  <span className="font-weight-bold text-right">£{service.price}</span>
                </div>
              </>
            }

            <hr />

            <div className={buttonContainerClass}>
              <Button variant="primary" onClick={() => navigate('/')}>
                Back To Homepage
                </Button>
            </div>
          </Col>
        </Row>
      </Container>
    </Layout>
  )
}

const serviceRowTotalClass = classNames("d-flex", "justify-content-between", "align-items-center", "mt-4")
const h4HeadingClass = classNames("text-uppercase", "h6", "text-grey-600")
const buttonContainerClass = classNames("d-flex", "justify-content-center", "my-5")


export default BicycleServices
